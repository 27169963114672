import _ from 'lodash'
import { gql } from '~/apollo/gql'
import { defineStore } from 'pinia'

export const useFiveFestSubmission = defineStore('five-fest-submission', () => {
  const session = useSession()
  const openFeature = useOpenFeature()
  const { currentRoute: route } = useRouter()

  const { result: festivalSubmissionsResult, refetch: reloadFestivalSubmissions } = useQuery(FestivalSubmissionsQuery, () => ({
    ffid: openFeature.getNumber('current_film_festival'),
    poster: openFeature.getObject('asset_type_ids').poster,
  }), () => ({
    enabled: !!session.user && openFeature.getBool('five_fest')
  }))
  const festivalSubmissions = computed(() => festivalSubmissionsResult.value?.festival_submissions)

  const submissionId = ref()
  const submissionQueryEnabled = ref(false)
  const { result: submissionResult, refetch: reloadSubmission } = useQuery(LoadFestivalSubmission, () => ({
    id: submissionId.value,
  }), () => ({
    enabled: submissionQueryEnabled.value,
  }))
  const submission = computed(() => submissionResult.value?.festival_submissions_by_pk)

  const poster = computed(() => _.chain(submission.value?.assets)
    .filter(asset => asset.asset_type.id == openFeature.getObject('asset_type_ids').poster)
    .orderBy(asset => asset.created_at, 'desc')
    .first()
    .value())

  const isSubmitted = computed(() => !!submission.value?.submitted_at)

  const { mutate: pay, onDone: onPay } = useMutation(SubmissionPayment, () => ({
    variables: {
      returnUrl: window.location.href.replace(window.location.hash, ""),
      lineItems: [{
        priceId: openFeature.getString('five_fest_price') as unknown as string,
        quantity: 1
      }],
      festivalSubmissionId: submission.value?.id!
    }
  }))
  onPay(({ data }) => {
    window.location.href = data?.url!
  })

  watch(route, ({ fullPath, params }) => {
    if (!fullPath.startsWith('/fivefest')) {
      return
    }

    if (params.submissionId) {
      submissionQueryEnabled.value = false
      submissionId.value = parseInt(params.submissionId as string)
      nextTick(() => {
        submissionQueryEnabled.value = true
      })
    }
  }, {
    immediate: true,
    deep: true,
  })

  const submissionAudit = computed(() => {
    const audit: {
      [key: string]: {
        text?: string
        icon: string
        bullets: {
          text: string
          subtext?: string
          advice?: string
          valid: boolean
          optional?: boolean
        }[]
        optional?: boolean
        valid?: boolean
        hasWarning?: boolean
      }
    } = {
      registration: {
        icon: 'mdi-account-outline',
        text: 'Personal Information',
        bullets: [{
          text: 'Personal Information Provided',
          subtext: 'Make sure your information is up to date so we know how to contact you.',
          advice: 'You have not provided your contact information.',
          valid: true
        }]
      },
      details: {
        icon: 'mdi-movie-open-outline',
        text: 'Film Details',
        bullets: [{
          text: 'Film Details Provided',
          subtext: 'Tell us about your film, including when it was made.',
          valid: true
        }]
      },
      payment: {
        icon: 'mdi-credit-card-outline',
        text: 'Payment',
        bullets: [{
          text: 'Pay Submission Fee',
          advice: 'You have not paid the submission fee.',
          valid: !!submission.value?.paid_at
        }]
      },
      film: {
        icon: 'mdi-movie-open-plus-outline',
        text: 'Film Upload',
        bullets: [{
          text: 'Film Uploaded and Processed',
          advice: 'You must upload your film, dummy!',
          subtext: 'Watch your film in the player above to verify it plays back correctly.',
          valid: !!submission.value?.mux_playback_id,
        }, {
          text: '5 Minutes or Less',
          subtext: 'Make sure your film is less than 5 minutes.',
          valid: Math.floor(submission.value?.mux_asset_data?.duration) <= 300,
        }]
      },
      poster: {
        icon: 'mdi-file-image-outline',
        text: 'Poster Upload',
        bullets: [{
          text: 'Poster Uploaded',
          subtext: 'Upload a poster for your film.',
          optional: true,
          valid: !!poster.value,
        }]
      },
      stills: {
        icon: 'mdi-file-image-outline',
        text: 'Promotional Materials',
        bullets: [{
          text: 'Promotional Materials Uploaded',
          subtext: 'Upload promotional materials for your film.',
          optional: true,
          valid: _.filter(submission.value?.assets, asset => asset.asset_type.id != openFeature.getObject('asset_type_ids').poster).length > 0
        }],
      },
      submit: {
        icon: 'mdi-send-outline',
        text: 'Submit',
        bullets: [{
          text: 'Finalize your submission',
          subtext: 'Once you submit, you will not be able to make any changes.',
          valid: isSubmitted.value,
        }],
      }
    }

    for (const step in audit) {
      audit[step].valid = _.every(audit[step].bullets, bullet => {
        return bullet.valid || bullet.optional
      })
      audit[step].hasWarning = _.some(audit[step].bullets, bullet => {
        return !bullet.valid && bullet.optional
      })
    }

    return audit
  })

  const { mutate, loading, onDone: onSaved, error: saveError } = useMutation(SaveFestivalSubmission)
  onSaved(() => {
    reloadSubmission()
    reloadFestivalSubmissions()
  })

  return {
    festivalSubmissions,

    submission,
    reloadSubmission,
    poster,
    isSubmitted,

    saveSubmission: mutate,
    saving: loading,
    saveError,

    submissionAudit,

    pay,
  }
})

const FestivalSubmissionsQuery = gql(`query FestivalSubmissionQuery($ffid: Int!, $poster: Int!) {
  festival_submissions(where: {film_festival_id: {_eq: $ffid}}) {
    id
    film_title
    tagline
    paid_at
    submitted_at
    poster: assets(where: {asset_type_id: {_eq: $poster}}, order_by: {created_at: desc}, limit: 1) {
      id
      file_name
    }
  }
}`)

const LoadFestivalSubmission = gql(`query LoadFestivalSubmission($id: Int!) {
  festival_submissions_by_pk(id: $id) {
    id
    first_name
    middle_name
    last_name
    primary_phone
    secondary_phone
    primary_email
    secondary_email
    address_1
    address_2
    city
    state
    zip
    country
    
    film_title
    tagline
    genre {
      id
      name
      description
    }
    production_year
    production_company
    synopsis

    mux_asset_id
    mux_upload_id
    mux_playback_id
    mux_asset_data

    paid_at
    submitted_at

    assets {
      id
      file_name
      asset_type {
        id
        title
      }
      created_at
    }

    vouchers {
      id
      team {
        id
        studio_id
        team_number
        name
      }
    }
  }
}`)

const SubmissionPayment = gql(`mutation SubmissionPayment(
  $returnUrl: String!
  $lineItems: [StripeLineItem!]!
  $festivalSubmissionId: Int!
) {
  url: stripeCreatePurchase(returnUrl:$returnUrl lineItems:$lineItems festivalSubmissionId:$festivalSubmissionId)
}`)

const SaveFestivalSubmission = gql(`mutation SaveFestivalSubmission($id: Int!, $_set: festival_submissions_set_input = {}) {
  update_festival_submissions_by_pk(pk_columns: {id: $id}, _set: $_set) {
    id
  }
}`)
